<template>
  <div class="addType commonF padding20 bgFFF paddingLR20 paddingT20">
    <h2 class="title">
      <div class="title_icon">
      </div>
      {{operation}}二级分类
    </h2>
    <div class="content">
      <el-form ref="form" class="commonF" :model="form" :rules="rule" label-width="150px">
        <el-form-item label="关联一级分类" prop="classifyOne">
          <el-select v-model.trim="form.classifyOne" filterable size="15" >
            <el-option
              :label="value.classifyOneName"
              :value="value.classifyId"
              :key="value.classifyId"
              v-for="value in tenantList"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二级分类名称" prop="classify">
          <el-input v-model="form.classify" maxlength="50" style="width:230px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.remarks')" prop="content">
          <el-input type="textarea" v-model="form.content" maxlength="200" style="width:230px"></el-input>
        </el-form-item>
        <div style="text-align: center; margin-top: 20px;">
          <el-button type="primary" v-show="operation === '添加'" @click="save('form')">{{ $t('button.preservation') }}</el-button>
          <el-button type="primary" v-show="operation !== '添加'" @click="update('form')">{{ $t('button.preservation') }}</el-button>
          <el-button @click="$router.go(-1)">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "addType",
  data() {
    return {
      tenantList: [],
      operation: "", // 操作
      form: {
        parentId1: "", // 一级分类id
        parentId2: "", // 二级分类id
        typeName: "", // 一级分类名称
        typeTwoName: "", // 二级分类名称
        titleName: "", // 标题
        content: "", // 内容/备注
        knowledge: "" // 关键字
      },
      rule: {
        classifyOne: [
          { required: true, message: "该项为必填项", trigger: "blur" }
        ],
        classify: [
          { required: true, message: "该项为必填项", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    init() {
      this.getAllOneName();
      this.operation = this.$route.query.operation
      if (this.operation == '编辑') {
        this.form = this.$route.query;
        this.$set(this.form, "classify", this.$route.query.classifyTwoName);
        this.$set(this.form, "content", this.$route.query.remarks);
        this.$set(this.form, "classifyOne", this.$route.query.classifyId);
        this.getDetails()
      }
    },
    save(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.$axios
            .post("/acb/2.0/customerClassify/add", {
              data: {
                ...this.form,
                type: 2
              }
            })
            .then(res => {
              if (res.state == 0) {
                this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                }).then(() => {
                  this.$router.go(-1);
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                });
              }
            });
        }
      });
    },
    getAllOneName() {
      this.$axios
        .get("/acb/2.0/customerClassify/list", {
          data: {
            type: 1,
            pageNum: 0,
            pageSize: 0
          }
        })
        .then(res => {
          if (res.state == 0) {
            this.tenantList = res.value.list;
          }
        });
    },
    update(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.$axios
            .post("/acb/2.0/customerClassify/update", {
              data: {
                ...this.form,
                type: 2
              }
            })
            .then(res => {
              if (res.state == 0) {
                this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                }).then(() => {
                  this.$router.go(-1);
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                });
              }
            });
        }
      });
    },
    getDetails() {
      this.$axios
        .get(`/acb/2.0/customerClassify/detail/${this.form.classifyId}`)
        .then(res => {
          if (res.state == 0) {
            this.$set(this.form, "classifyOne", res.value.parentId);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        });
    }
  },
  mounted() {
    this.init();
  }
};
</script>
